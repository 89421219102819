import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { platformBrowser } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ){}
  canActivate(
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if ((this.authService.authenticated() && isPlatformBrowser(this.platformId))) {
        // this.router.navigate(['signin']);
        return true;
      }else if(!this.authService.authenticated() && isPlatformBrowser(this.platformId)){
        this.router.navigate(['signin']);
        return false;
      }
      // return true
      return false;
  }
  
}
